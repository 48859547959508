import React from "react";
import "./Loader.css";

export const Loader = () => {
  return (
    <div className="body">
      <h1>HOLIDAY ALBUM</h1>
      <ul className="loader">
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
};
