import React from 'react'

export const ErrorPage = () => {
  return (
    <div className="edit-screen">
      <h1 className='m-5'> HOLIDAY ALBUM </h1>
      <div className='btn'>
       PAGE NOT FOUND 
      </div>
    </div>
  )
}
