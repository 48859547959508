import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import CollectionsIcon from "@mui/icons-material/Collections";
import { SkinList } from "../SkinList/SkinList";
import { AttributeListContainer } from "../AttributeListContainer/AttributeListContainer";
import { Link } from "react-router-dom";

import "./MainNFT.scss";

export const MainNFT = ({ image, name, id, skins, attributes, setImage }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [seeSkins, setSeeSkins] = useState(true);
  const [seeAttributes, setSeeAttributes] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleSkins = () => {
    setSeeSkins(true);
    setSeeAttributes(false);
  };

  const toggleAttributes = () => {
    setSeeSkins(false);
    setSeeAttributes(true);
  };

  return (
    <div
      className="main-nft"
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <div className="flex justify-start">
        <CollectionsIcon
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className="menu-icon m-2"
        />
      </div>
      <div>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          className="menu"
        >
          <div className="menu-items m-2">
            <div className="flex justify-center nft-title">
              <h1>HOLIDAY ALBUM</h1>
            </div>

            <div className="flex justify-around inside-btn">
              <button
                onClick={() => toggleSkins()}
                className="m-2 text-white photos-button"
              >
                PHOTOS
              </button>
              <button
                onClick={() => toggleAttributes()}
                className="m-2 text-white details-button"
              >
                DETAILS
              </button>
            </div>
            {seeSkins ? (
              <SkinList skins={skins} setImage={setImage} />
            ) : (
              <AttributeListContainer attributes={attributes} />
            )}
          </div>
        </Menu>
      </div>

      {/* <img src={image} alt="" className="size"/> */}
    </div>
  );
};
