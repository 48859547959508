import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "../components/Loader/Loader";
import { MainNFT } from "../components/MainNFT/MainNFT";

import "./Home.scss";

export const Home = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [attributes, setAttributes] = useState([]);
  const [skins, setSkins] = useState([]);

  useEffect(() => {
    pullMetadata();
  }, []);

  const pullMetadata = async () => {
    const apiUrl = `https://dev.nftinkorea.com/1`;
    const resp = await fetch(apiUrl)
      .then((resp) => resp.json())
      .then((json) => {
        setName(json.name);
        setImage(json.image);
        setAttributes(
          json.attributes.map((attribute) => ({
            trait_type: attribute.trait_type,
            value: attribute.value,
          }))
        );
        setSkins(
          json.skins.map((skin) => ({
            id: skin.id,
            skinName: skin.name,
            skinImage: skin.skin,
          }))
        );
        setTimeout(() => {
          setIsLoading(false);
        }, 700);
      });

    console.log("skins =>", skins);
    console.log("attributes =>", attributes);
    return null;
  };

  return (
    <div className="screen">
      <div className="size">
        {isLoading ? (
          <Loader />
        ) : (
          <MainNFT
            image={image}
            name={name}
            id={id}
            skins={skins}
            attributes={attributes}
            setImage={setImage}
          />
        )}
      </div>
    </div>
  );
};
