import React from "react";
import { Attribute } from "../Attribute/Attribute";

export const AttributeList = ({ attributes }) => {
  return (
    <>
      {attributes.map((attribute, index) => {
        return (
          <div key={index + 1}>
            <Attribute attribute={attribute} />
          </div>
        );
      })}
    </>
  );
};
