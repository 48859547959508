import React from "react";
import Skin from "../Skin/Skin";

export const SkinList = ({ skins, setImage }) => {
  return (
    <>
      {skins.map((skin, index) => {
        return (
          <div key={skin.id}>
            <Skin skin={skin} setImage={setImage} />
          </div>
        );
      })}
    </>
  );
};
