import React, { useEffect, useState } from "react";
import "./Skin.css";

function Skin({ skin, setImage }) {
  const { skinImage, id, skinName } = skin;

  return (
    <div className="skin-section p-4">
      <div className="flex justify-around">
        <span className="photo-title">Photo: {skinName}</span>
      </div>
      <div className="flex justify-center">
        <img
          src={skinImage}
          alt=""
          onClick={() => setImage(skinImage)}
          className="skin-img"
        />
      </div>
    </div>
  );
}

export default Skin;
