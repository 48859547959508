import React from "react";
import "./Attribute.css";

export const Attribute = ({ attribute }) => {
  const { trait_type, value } = attribute;

  return (
    <div>
      <div className="flex content-center skin-section">
        <div className="w-1/2 m-2">
          <span style={{ fontWeight: 700, textTransform: "capitalize" }}>
            {trait_type}
          </span>
        </div>
        <div className="w-1/2 m-2">
          <span>{value}</span>
        </div>
      </div>
      <hr className="hr-attributes" />
    </div>
  );
};
