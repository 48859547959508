import React from "react";
import { AttributeList } from "../AttributeList/AttributeList";
import './AttributeListContainer.css';

export const AttributeListContainer = ({ attributes }) => {
  return (
    <div>
      <div className="flex attribute-column">
        <div className="w-1/2 m-2">
          <span>INFO</span>
        </div>
        <div className="w-1/2 m-2">
          <span>DESCRIPTION</span>
        </div>
      </div>
      <hr className="hr-attribute-column"/>
      <AttributeList attributes={attributes} />
    </div>
  );
};
